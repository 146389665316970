
.formclass {

    /*display: block ;*/

    color: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 50;


}


.backemployer {

    /*height: 200vh;*/

    height: 100%;
    overflow: auto;

    background: linear-gradient(45deg, #7507FA 10% , #1A1423 90% , #19A5F4  100%);

    color : white ;



}

.errclass {
    color: white ;

    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}