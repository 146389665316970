@font-face {
    font-family: "Satoshi-Bold";
    src: local("Satoshi-Bold"),
      url("./fonts/Satoshi-Bold.ttf") format("truetype");
  }
  @font-face {
    font-family: "Satoshi-BoldItalic";
    src: local("Satoshi-BoldItalic"),
      url("./fonts/Satoshi-BoldItalic.ttf") format("truetype");
  }
  @font-face {
    font-family: "Satoshi-Italic";
    src: local("Satoshi-Italic"),
      url("./fonts/Satoshi-Italic.ttf") format("truetype");
  }
  @font-face {
    font-family: "Satoshi-Medium";
    src: local("Satoshi-Medium"),
      url("./fonts/Satoshi-Medium.ttf") format("truetype");
  }
  @font-face {
    font-family: "Satoshi-MediumItalic";
    src: local("Satoshi-MediumItalic"),
      url("./fonts/Satoshi-MediumItalic.ttf") format("truetype");
  }
  @font-face {
    font-family: "Satoshi-Regular";
    src: local("Satoshi-Regular"),
      url("./fonts/Satoshi-Regular.ttf") format("truetype");
  }
  body {
    font-family: "Satoshi-Regular"!important;
    color: #1A1423;
    background-color: #EBEBEB!important;
  }
  .font-regular {
    font-family: "Satoshi-Regular";
  }
  .font-italic {
    font-family: "Satoshi-Italic";
  }
  .font-medium {
    font-family: "Satoshi-Medium";
  }
  .font-medium-italic {
    font-family: "Satoshi-MediumItalic";
  }
  .font-bold {
    font-family: "Satoshi-Bold";
  }
  .font-bold-italic {
    font-family: "Satoshi-BoldItalic";
  }
   /* HEADER */
   .header {
    height: 75px;
    background-color: #ffffff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    
   }


  /*GENERALES TEXTO*/

  a {
    color: white;
  }
  .h1 {
    font-size: 34px;
    line-height: 745px;
  }
  .h2 {
    font-size: 20px;
    line-height: 27px;
  }
  .h3 {
    font-size: 18px;
    line-height: 24px;
  }
  .h4 {
    font-size: 18px;
    line-height: 24px;
  }
  .body-0 {
    font-size: 34px;
    line-height: 45px;
  }
  .body-1 {
    font-size: 18px;
    line-height: 24px;
  }
  .body-2 {
    font-size: 16px;
    line-height: 22px;
  }
  .body-3 {
    font-size: 14px;
    line-height: 18px;
  }
  .body-4 {
    font-size: 13px;
    line-height: 17px;
  }
  .body-5 {
    font-size: 11px;
    line-height: 15px;
  }
  .font-regular {
    font-family: "Satoshi-Regular"!important;
  }
  .font-italic {
    font-family: "Satoshi-Italic"!important;
  }
  .font-medium {
    font-family: "Satoshi-Medium"!important;
  }
  .font-medium-italic {
    font-family: "Satoshi-MediumItalic"!important;
  }
  .font-bold {
    font-family: "Satoshi-Bold"!important;
  }
  .font-bold-italic {
    font-family: "Satoshi-BoldItalic"!important;
  }
  /*GENERALES COLOR*/

  /* TIPOGRAFIA DE COLOR*/

.tx-color-cyan {
   color: #19A5F4;
}
.tx-color-purple {
    color: #7507F4;
}
.tx-color-green {
    color: #61E294;
}
.tx-color-black {
    color: #1A1423;
}
.tx-color-pink {
    color: #F500FF;
}
.tx-color-gray-light1 {
  color:#F9F8F8;
}
.tx-color-gray-light2 {
  color:#F5F5F5;
}
.tx-color-gray-medium {
  color:#EBEBEB;
}
.tx-color-gray-dark {
  color:#A5A5A5;
}
.tx-color-orange {
  color:#FF7F00;
}
.tx-gradient-bluepurple {
    background: linear-gradient(to right, #19A5F4 , #7507F4);    
    background-clip: text;
    -webkit-text-fill-color: transparent;
}  
.tx-gradient-bluegreen {
    background: linear-gradient(to right, #19A5F4 , #61E294);   
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
  
 /* BACKGROUND COLOR*/

.bg-color-cyan {
    background-color: #19A5F4;
}
.bg-color-purple {
    background-color: #7507F4;
}
.bg-color-green {
    background-color: #61E294;
}
.bg-color-black {
    background-color: #1A1423;
}
.bg-color-pink {
    background-color: #F500FF;
}
.bg-color-gray-light1 {
    background-color:#F9F8F8;
}
.bg-color-gray-light2 {
    background-color:#F5F5F5;
}
.bg-color-gray-medium {
    background-color:#EBEBEB;
}
.bg-color-gray-dark {
    background-color:#A5A5A5;
}
.bg-color-orange {
  background-color:#FF7F00;
}
.bg-gradient-bluepurple {
    background: linear-gradient(to right, #19A5F4 , #7507F4);    
}  
.bg-gradient-bluegreen {
    background: linear-gradient(to right, #19A5F4 , #61E294);   
}
.bg-focus-cyan:hover {
  background-color: rgba(25, 165, 244, 0.15)!important;
}


/* Cajas */

.box-1 {
    background-color: white;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 32px;
}
.box-2 {
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 16px;
}
.box-3 {
  display: flex;
  background-color: #ffffff;
  /*width: 125px;*/
  width: 100%;
  height: 125px;
  border: 1px solid #EBEBEB;
  border-radius: 10px;
  padding: 16px;
  justify-content: center;
  align-items: center;  
}
.box-4 {  
  background-color: #ffffff;
  width: 340px;
  height: 100%;
  border: 1px solid #EBEBEB;
  border-radius: 10px;
  padding: 16px;
}
.box-5 {  
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  border: 1px solid #EBEBEB;
  border-radius: 10px;
  padding: 16px;
}
.box-6 {
  width: auto;
  font-size: 13px;
  border: 1px solid #A5A5A5;
  border-radius: 20px;
  padding: 5px 15px;  

}
.box-7 {
  background-color: #EBEBEB;
  width: 100%;
  height: auto;
  border-radius: 5px;
  padding: 16px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}
.box-8 {
  width: 100%;
  background: transparent linear-gradient(180deg, #28A7F1 0%, #7507FA 100%);
  padding: 16px;

}

/* Formularios */

.form-1 input {
    background: #F5F5F5;
    border-radius: 5px;
    width: 100%;
    padding: 15px 20px;
    font-size: 13px!important;
    font-weight: 400;
    color: #A5A5A5;
    border: none;
}


.form-1 label {
    display:none!important;
}
input:focus, input:active {
  outline: 1px solid #7507F4!important;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
 }

/* Botones */

.button-1 {
    width: 100%;
    padding: 10px 20px;
    background: transparent linear-gradient(90deg, #28A7F1 0%, #7507FA 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    font-size: 14px!important;
    font-weight: 500;
    border: none;
    border-radius: 5px;
}
.button-1:hover {
    background: transparent linear-gradient(90deg, #7507FA 0%, #28A7F1 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.3);

}
.button-2 {
  width: 100%;
  padding: 5px 20px;
  background: transparent linear-gradient(90deg, #28A7F1 0%, #7507FA 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  font-size: 14px!important;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  text-align: center;
}
.button-2:hover {
  background: transparent linear-gradient(90deg, #7507FA 0%, #28A7F1 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.3);
}
.button-3 {
  padding: 5px 20px;
  background: transparent linear-gradient(90deg, #28A7F1 0%, #7507FA 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  font-size: 14px!important;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  text-align: center;
}
.button-3:hover {
  background: transparent linear-gradient(90deg, #7507FA 0%, #28A7F1 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.3);
}
.button-4 {
  width: 100%;
  padding: 5px 20px;
  background: #A5A5A5;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  font-size: 14px!important;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  text-align: center;
}
.button-4:hover {
  background: #28A7F1;
  box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.3);
} 

/* Circulos */

.circle-not {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px; 
  color: #ffffff;
  width: 25px;
  height: 25px;
  background: #7526F6;
  border-radius: 50%;
  }
  .circle-green::before {
    content: "";
    display: inline-block;
    background: #61E294;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .circle-pink::before {
    content: "";
    display: inline-block;
    background: #F500FF;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .circle-black::before {
    content: "";
    display: inline-block;
    background: #1A1423;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .circle-cyan::before {
    content: "";
    display: inline-block;
    background: #19A5F4;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .circle-purple::before {
    content: "";
    display: inline-block;
    background: #7507FA;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .circle-orange::before {
    content: "";
    display: inline-block;
    background: #FF7F00;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .circle-gray-light1::before {
    content: "";
    display: inline-block;
    background: #F9F8F8;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .circle-gray-light2::before {
    content: "";
    display: inline-block;
    background: #F5F5F5;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .circle-gray-medium::before {
    content: "";
    display: inline-block;
    background:#EBEBEB;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .circle-gray-dark::before {
    content: "";
    display: inline-block;
    background: #A5A5A5;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
  }

 /* Sam Styles */
 .form-1-boot  {
  background: #F5F5F5;
  border-radius: 5px;
  width: 100%;
  padding: 15px 20px;
  font-size: 13px!important;
  font-weight: 400;
  color: #A5A5A5;
  border: none;

}


.bg-image1 { /* para la imagen de fondo de Login */
  
  background-image: url( '../../public/hh-background-login.jpg');

  height: 100vh;  
  background-size: cover;
  background-position: center center; 

  color: white;
}

.login-01 { /* Ajuste del logo de h&h en la pantalla del login */
  display: flex;
  justify-content: center;
  height: 80px;
  padding-top: 30px;

  opacity: 1;

}

.login-02 {
  top: 307px;
  left: 676px;
  width: 100%;
  height: 320px;
  

  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #0000001A;
  border-radius: 5px;
  opacity: 1;
}

.login-center { /* centrar el formulario del login */
  margin: auto;
  margin-top: 80px;
  width:50%;
/*  border: 3px solid #2621ad;
  padding: 10px; */
}


.dash-left {
  top: 0px;
  left: 0px;
  width: 70px;
  height: 100vh;

  background: var(--unnamed-color-1a1423) 0% 0% no-repeat padding-box;
  background: #1A1423 0% 0% no-repeat padding-box;
  opacity: 1;
  
  
}

.dash-vertical {
  vertical-align: top;
}

.dash-middle {
  vertical-align: middle;
}



.bg-right {
  background: var(--gray-medium) 0% 0% no-repeat padding-box;
  background: #EBEBEB 0% 0% no-repeat padding-box;
  opacity: 1;
}

.circle {
  height: 44px;
  width: 44px;
  /*background-color: #bbb; */
  border-radius: 50%;
  display: inline-block;
  background: var(--cyan) 0% 0% no-repeat padding-box;
  background: #19A5F4 0% 0% no-repeat padding-box;
  opacity: 1;

}


#search001::placeholder {
  color: white !important;
  opacity: 1;
  }


.box-top {
  /*width: 127px;*/
  width : 100% ;
  height: 125px;
  
  /* UI Properties */
  border: 1px solid var(--gray-medium);
  border: 1px solid #EBEBEB;
  border-radius: 10px;
  opacity: 1;
}

.div-inline {
  display: inline-block;
}


.vl {
  border-left: 2px solid lightgray;
  height: 120px;
}

div.ex1 {
 
  height: 330px;
  width: 100%;
  overflow-y: scroll;
}

div.ex2 {
 
  height: 447px;
  width: 100%;
  overflow-y: scroll;
}

@media screen and (max-width: 1014px) {
  .font-medium {
    font-family: "Satoshi-Medium";
    font-size: 8px ;
    height: 24px ;
  }

  .font-regular {
    font-family: "Satoshi-Regular";
    font-size: 8px ;
    /*height: 24px ;*/
  }



}


@media screen and (min-width:1014px) and (max-width: 1200px) {
  .font-medium {
    font-family: "Satoshi-Medium";
    font-size: 12px ;
    height: 24px ;
  }
  .font-regular {
    font-family: "Satoshi-Regular";
    font-size: 10px ;
    /*height: 24px ;*/
  }


}


@media screen and (min-width:1200px) and  (max-width: 1600px) {
  .font-medium {
    font-family: "Satoshi-Medium";
    font-size: 16px ;
    height: 24px ;
  }

  .font-regular {
    font-family: "Satoshi-Regular";
    font-size: 12px ;
    /*height: 24px ; */
  }

  .news-item{
    height: 71px;
  }

  .news-item:hover {
    background-color: rgba(25, 165, 244, 0.15)!important;;
  }


  .reg-item{
    height: 38px;
  }

  .reg-item:hover {
    background-color: rgba(25, 165, 244, 0.15)!important;;
  }




}

.overflow {
  
  overflow-y: scroll;
}


.overlay {
  position: relative;
  top: 10px;
  left: -80px;
  z-index: 9;
  
}

.drop-item {
  height: 30px;
}

.drop-item:hover {
  background-color: rgba(193, 212, 223, 0.15)!important;;
}

.postable {
  margin-top : 5px ;

  margin-left : 37px ; 
  
}

.backemployer-2 {

  /*height: 200vh;*/

  height: 100%;
  overflow: auto;

  /*background: linear-gradient(45deg, #7507FA 10% , #1A1423 90% , #19A5F4  100%);*/

  color : black ;



}

.vertical-center {
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* elementos para el editor */
.App-header {
  background-color: #2152b3;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin-bottom: 5vh;
  text-align: center;
  padding: 12px;
 }

 .wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}
.editor-class {
  background-color:lightgray;
  padding: 1rem;
  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
}