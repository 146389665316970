.formclass {

    /*display: block ;*/

    color: white;

    display: flex;
    /*flex-direction: column;*/
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;

    padding: 50;


}


.back {

    height: 100vh;

    color : white ; 

    background: linear-gradient(45deg, #7507FA 10% , #1A1423 90% , #19A5F4  100%);



}

.errclass {
    color: white ;

    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.html {
    font-size: 14px;
} 

.top {
    padding-top: 10px;
}